<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="19" y1="12" x2="7" y2="12" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    <line x1="7" y1="4" x2="7" y2="20" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
    export default {
        props: ['w', 'h'],
        name: 'MarkStart',
        data() {
            return {
                width: this.w || 24,
                height: this.h || 24
            }
        }
    }
</script>