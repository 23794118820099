<template>
    <div 
        class="bg-white fixed top-0 left-0 w-full h-full bg-opacity-95 transition-opacity duration-200 z-50 overflow-y-auto"
        :class="{ 'invisible opacity-0': !isOpen, 'opacity-100 visible': isOpen }"
    >
        <div class="absolute top-5 right-5 w-8 h-8 flex items-center justify-center text-gray-700 hover:bg-gray-200 hover:text-gray-800" @click="$emit('close')">
            <XIcon size="1.5x"/>
        </div>
        <div class="pt-16 lg:mt-0 container flex lg:items-center justify-center h-screen mx-auto px-4">
            <div class="max-w-screen-sm">
                <p class="text-lg text-grey-800 lg:text-2xl mb-2 text-center">Add a Video</p>
                <p>Paste in a youtube video url below to add it to your videos.</p>
                <div class="flex flex-wrap items-center my-4">
                    <label for="#url" class="text-xs uppercase text-gray-500 tracking-wide block w-full mb-2">Url</label>
                    <input id="url" class="border border-gray-200 rounded p-2 flex flex-1" type="text" placeholder="https://youtu.be/dQw4w9WgXcQ" v-model="url" @change="handleChange">
                </div>
                <div ref="wrapper" class="w-full">
                    <div ref="player"></div>
                </div>
                <p class="text-sm text-gray-700 mt-4 p-2 bg-yellow-100 text-center rounded" v-show="videoExists">It looks like you've already added this video</p>
                <div class="mt-4" v-show="isValidId">
                    <label for="" class="text-xs uppercase text-gray-500 tracking-wide mb-2 block">Name</label>
                    <input class="border border-gray-200 rounded p-2 flex flex-1 w-full" type="text" placeholder="Video Name/Title" v-model="name">
                </div>
                <button 
                    class="w-full rounded text-center text-white py-3 px-4 bg-primary mt-6" 
                    :class="{ 'opacity-100': canAddVideo, 'opacity-20 cursor-not-allowed': !canAddVideo }"
                    :disabled="!canAddVideo" 
                    @click="handleAddVideo"
                >Add Video</button>
            </div>
        </div>
    </div>
</template>

<script>
    // https://www.youtube.com/watch?v=37n4cl9cwj8
    import YTPlayer from 'yt-player';
    import getYoutubeId from 'get-youtube-id';
    import db from '../db';
    import { XIcon } from 'vue-feather-icons';
    
    export default {
        name: 'AddModal',
        props: ['isOpen'],
        emits: ['close', 'video-added'],
        components: {
            XIcon
        },
        data() {
            return {
                url: '',
                name: '',
                player: null,
                isValidId: false,
                videoId: null,
                videoExists: false,
                videoDur: 0
            }
        },
        mounted() {
            if (this.$refs.player && this.$refs.wrapper) {
                this.player = new YTPlayer(this.$refs.player, {
                    width: this.$refs.wrapper.clientWidth,
                    height: this.$refs.wrapper.clientWidth/(16/9),
                    timeupdateFrequency: 2000
                });

                window.addEventListener('keyup', (e) => {
                    const { key } = e;
                    if (this.isOpen) {
                        if (key === 'Escape') {
                            this.$emit('close');
                        }
                    }
                })

                // This seems to cause a pretty bad infinite loop-like issue in safari
                // this.player.on('timeupdate', () => {
                //     if (this.videoDur === 0) {
                //         let dur = this.player.getDuration();
                //         this.videoDur = dur;
                //     } else {
                //         this.player.stop();
                //     }
                // })
            }
        },
        computed: {
            canAddVideo() {
                // return this.isValidId && this.name !== '' && this.videoDur > 0;
                return this.isValidId && this.name !== '';
            }
        },
        methods: {
            async handleChange() {
                if (this.url && this.url !== '') {
                    let id = getYoutubeId(this.url);
                    if (id) {
                        this.isValidId = true;
                        this.videoId = id;
                        this.videoExists = await db.videos.where('videoId').equals(this.videoId).count() > 0 ? true : false;
                        this.player.load(id);
                        if (!this.videoExists) {
                            this.player.mute();
                            this.player.play();
                            // hopefully 3 secs is long enough to make this work
                            setTimeout(() => {
                                let dur = this.player.getDuration();
                                this.player.stop();
                                this.videoDur = dur;
                            }, 3000);
                        }
                    } else {
                        this.isValidId = false;
                    }
                }
            },
            async handleAddVideo() {
                let videoExists = await db.videos.where('videoId').equals(this.videoId).count() > 0 ? true : false;
                if (!videoExists) {
                    try {
                        await db.videos.put({
                            videoId: this.videoId,
                            name: this.name,
                            duration: this.videoDur
                        })
                        this.$emit('close');
                        this.$emit('video-added');
                        this.$router.push(`/${this.videoId}`);
                        // do a bit of tidy up
                        this.reset();
                    } catch(err) {
                        console.log('failed to add a new video');
                        console.log(err);
                    }
                }
                if (videoExists) {
                    this.videoExists = true;
                }
            },
            reset() {
                this.videoDur = 0;
                this.videoExists = false;
                this.videoId = null;
                this.isValidId = null;
                this.name = '';
            }
        },
        watch: {
            isOpen(val) {
                this.videoExists = false;
                this.url = '';
                this.player.stop();
                let html = document.querySelector('html');
                if (val) {
                    html.classList.add('overflow-hidden');
                } else {
                    html.classList.remove('overflow-hidden');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>