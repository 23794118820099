<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="-1" x2="13" y2="-1" transform="matrix(1 8.74228e-08 8.74228e-08 -1 6 11)" stroke="currentColor" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-dasharray="3 3"/>
        <line x1="1" y1="-1" x2="17" y2="-1" transform="matrix(0 1 1 0 20 3)" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
    export default {
        props: ['w', 'h'],
        name: 'CountIn',
        data() {
            return {
                width: this.w || 24,
                height: this.h || 24
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>