<template>
  <div id="app" class="font-sans bg-gray-100">
    <div class="container mx-auto py-4 px-2 flex flex-col min-h-screen">
      <Header @add-video="addVideoModalOpen = true"/>
      <router-view 
        @open-modal="addVideoModalOpen = true" 
        @video-deleted="fetchVideos"
        @fetch-videos="fetchVideos"
        :videos="videos"
      />
      <div class="py-8 mt-auto">
        <p class="text-gray-500 text-center">Made with ♥ by <a href="http://paulbird.co" class="text-primary" target="_blank">Paul Bird</a></p>
        <p class="text-gray-500 text-center text-sm mt-1">&copy; Paul Bird {{ year }}. All rights reserved.</p>
      </div>
    </div>
    <AddVideo 
      :isOpen="addVideoModalOpen" 
      @close="addVideoModalOpen = false"
      @video-added="fetchVideos"
    />
  </div>
</template>

<script>
import Header from './components/Header';
import AddVideo from './components/AddVideo';
import db from './db';

export default {
  name: 'App',
  components: {
    Header,
    AddVideo
  },
  data() {
    return {
      addVideoModalOpen: false,
      videos: [],
      year: new Date().getFullYear()
    }
  },
  mounted() {
    this.fetchVideos();
  },
  methods: {
    async fetchVideos() {
        let videos = await db.videos.toArray();
        this.videos = videos;
    }
  }
}
</script>

<style lang="css">
  :root {
    --pink: #EB459F;
    --orange: #F48117;
    --blue: #3FA6A0;
    --primary: var(--pink);
  }
</style>
