<template>
  <router-link :to="`/${video.videoId}`" class="p-2 w-full">
    <div class="p-4 bg-white rounded border border-black shadow border-opacity-10 text-gray-700 h-full flex items-center transition-all transform-gpu hover:scale-105 hover:shadow-md">
      <div class="mr-4" v-html="icon"></div>
      <div>
          <p class="text-lg mb-3">{{ video.name }}</p>
          <p class="text-gray-500">Duration: <span class="text-gray-700">{{ duration }}</span></p>
          <p class="text-gray-500">Loops: <span class="text-gray-700">{{ loops.length }}</span></p>
      </div>
    </div>
  </router-link>
</template>

<script>
import sf from 'seconds-formater';
import db from '../db';
import { toSvg } from 'jdenticon';

export default {
  props: ["video"],
  name: "VideoCard",
  data() {
      return {
          loops: []
      }
  },
  async mounted() {
    let loops = await db.loops.where('videoId').equals(this.video.id);
    this.loops = await loops.toArray();
  },
  computed: {
      duration() {
          return sf.convert(Math.floor(this.video.duration)).format('M:SS');
      },
      icon() {
          return toSvg(this.video.name, 80);
      }
  }
};
</script>

<style lang="scss" scoped>
</style>