<template>
    <div class="py-8 lg:py-8">
      <h1 class="text-2xl lg:text-3xl text-gray-800 text-center mb-6">Your Collection</h1>

      <div v-show="videos.length === 0">
        <p class="text-center text-lg text-grey-600 mb-2">Loopsome allows you to add loops to any YouTube video.</p>
        <p class="text-center text-lg text-grey-600">Get started by clicking the button below to add your first video.</p>
        <button class="flex items-center px-3 py-2 bg-primary text-white rounded mt-4 mx-auto" @click="handleClick">
          <PlusIcon/>
          <span class="ml-1">Add Video</span>
        </button>
      </div>

      <div class="max-w-screen-md mx-auto" v-if="videos.length > 0">
        <div class="flex flex-wrap">
            <VideoCard
                v-for="video in videos"
                :key="video.id"
                :video="video"
            />
        </div>
        <div class="py-6">
            <button class="flex items-center justify-center px-4 py-4 text-primary rounded mx-auto border-primary border-dashed border-2 w-3/4 opacity-70 hover:opacity-100 transition-opacity duration-150" @click="handleClick">
                <PlusIcon size="1.2x"/>
                <span class="ml-1">Add Video</span>
            </button>
        </div>
      </div>
    </div>
</template>

<script>
    import { PlusIcon } from 'vue-feather-icons';
    import VideoCard from '../components/VideoCard';

    export default {
        props: ['videos'],
        name: 'Home',
        components: {
            PlusIcon,
            VideoCard
        },
        data() {
            return {
                
            }
        },
        mounted() {
            this.$emit('fetch-videos');
        },
        methods: {
            handleClick() {
                this.$emit('open-modal');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>