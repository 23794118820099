import Vue from 'vue'
import App from './App.vue'
import './index.css';
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';
import Home from './views/Home.vue';
import VideoView from './views/Video.vue';
import WhatView from './views/What.vue';
import HowView from './views/How.vue';
import ImportView from './views/Import.vue';

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Toasted);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/what',
    name: 'What',
    component: WhatView
  },
  {
    path: '/how',
    name: 'How',
    component: HowView
  },
  {
    path: '/import',
    name: 'Import',
    component: ImportView
  },
  {
    path: '/:videoId',
    name: 'Video',
    component: VideoView
  }
];

const router = new VueRouter({
  routes
})


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
