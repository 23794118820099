<template>
    <div>
        <div class="bg-blue-700 bg-opacity-40 h-6 absolute top-2 left-0 rounded-l-sm" v-show="countInMode && ghostOffset > 0" :style="{
            transform: `translateX(${ghostOffset}px)`,
            width: `${ghostWidth}px`
        }">
            <!-- This is a ghost one for doing the lead in -->
        </div>
        <div class="bg-blue-700 h-6 absolute top-2 left-0 rounded-sm" :style="{
            transform: `translateX(${offset}px)`,
            width: `${width}px`
        }"
        @click="$emit('loop:clicked', id)"
        >
            <!-- nothing in here -->
        </div>
    </div>
</template>

<script>
    export default {
        props: ['start', 'end', 'stepAmount', 'id', 'countInMode', 'countInAmount'],
        name: 'LoopBar',
        data() {
            return {
                
            }
        },
        computed: {
            offset() {
                return this.start * this.stepAmount;
            },
            width() {
                return (this.end - this.start) * this.stepAmount;
            },
            ghostOffset() {
                return (this.start - parseInt(this.countInAmount)) * this.stepAmount;
            },
            ghostWidth() {
                return parseInt(this.countInAmount) * this.stepAmount;
            }
        },
        mounted() {
            
        }
    }
</script>

<style lang="scss" scoped>

</style>