<template>
    <div class="py-16 container mx-auto">
        <div class="content px-4 mx-auto">
            <h1 class="text-3xl mb-16 text-gray-900 text-center">How to use</h1>
            <h2 class="text-2xl mb-6 text-gray-900">Using the Playhead/Marker</h2>
            <p class="mb-4">
                The flow for adding loops is based around the draggable playhead. This is always kept in sync with the video and vice versa. 
            </p>
            <p class="mb-4">
                When making and editing loops it's best to always be using the playhead for scrubbing/elasped time instead of the YouTube player controls as this ensures keyboard shortcuts work.
            </p>
            <p class="p-4 bg-yellow-100 mb-8">
                Without getting technical the reason being is because once you click the YouTube player controls it has the focus and all key presses go to that instead. 
                <br><br>If you're experiencing issues such as the loop start and end won't set. Please ensure you've clicked anywhere outside of the YouTube player.
            </p>
            <h2 class="text-2xl mb-6 text-gray-900">Jumping back and forth</h2>
            <p class="mb-4">
                You can jump back and forth in increments of 5 and 1 seconds.
            </p>
            <p class="mb-4">
                To jump in 5 seconds increments use the left arrow (&larr;) and right arrow keys (&rarr;)
            </p>
            <p class="mb-4">
                To jump in 1 second increments use the comma(,) and period/full stop(.)
            </p>
            <h2 class="text-2xl mb-6 text-gray-900">Setting the Start and End of a loop</h2>
            <p class="mb-4">
                You can set the start or end of a loop by pressing the loop start 
                <MarkStart class="inline-flex w-8 h-8 p-1 mx-1 border border-black border-opacity-20 rounded-sm shadow-sm"/> 
                or loop end 
                <MarkEnd class="inline-flex w-8 h-8 p-1 mx-1 border border-black border-opacity-20 rounded-sm shadow-sm"/> buttons. 
                Alternatively you can use keys (s) or (e). 
            </p>
            <p class="mb-4">
                These will affect only the current active loop.
            </p>
            <p class="mb-8">
                You can set the start or end at anytime while the video is playing or paused. This is useful while you're dragging/scrubbing the playhead.
            </p>
            
            <h2 class="text-2xl mb-6 text-gray-900">Looping the active/current loop</h2>
            <p class="mb-4">
                Once you have your first loop and it's active you can press the loop mode button <RepeatIcon class="inline-flex w-8 h-8 p-1 mx-1 border border-black border-opacity-20 rounded-sm shadow-sm"/> or (l) to activate it. 
            </p>
            <p class="mb-8">
                This will now loop back to the begining of the loop once it's past the end of the active loop. It will loop infinitely for as long as Loop Mode is active and the video is playing.
            </p>
            
            <h2 class="text-2xl mb-6 text-gray-900">Restart the current loop</h2>
            <p class="mb-4">
                If you mess up during the current loop, you can easily restart to loop by hitting the restart buton <RefreshCcwIcon class="inline-flex w-8 h-8 p-1 mx-1 border border-black border-opacity-20 rounded-sm shadow-sm"/> or (r)
            </p>
            <p class="mb-8">
                You can also click directly on the loop to restart/jump to the start of it.
            </p>
            
            
            <h2 class="text-2xl mb-6 text-gray-900">Count In/Lead Time</h2>
            <p class="mb-4">
                Sometimes you need time to get your hands ready or to hear the few previous bars to anticipate when you should start. This is useful for if you've precisely set up your loops and don't want to permanently modify them.
            </p>
            <p class="mb-4">
                This temporarily adds 3 seconds onto all the loop starts (so it starts earlier) while this mode is active. It can be toggled via the button <CountIn class="inline-flex w-8 h-8 p-1 mx-1 border border-black border-opacity-20 rounded-sm shadow-sm"/> or by pressing (c).
            </p>
            
        </div>
    </div>
</template>

<script>
    import MarkStart from '../components/icons/MarkStart';
    import MarkEnd from '../components/icons/MarkEnd';
    import CountIn from '../components/icons/CountIn';

    import { 
        RepeatIcon,
        RefreshCcwIcon
    } from 'vue-feather-icons';
    
    export default {
        name: 'How',
        components: {
            MarkStart,
            MarkEnd,
            RepeatIcon,
            RefreshCcwIcon,
            CountIn
        }
    }
</script>

<style lang="css" scoped>
    .content {
        width: 100%;
        max-width: 80ch;
        @apply text-gray-700;
    }
</style>