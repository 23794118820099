<template>
    <div class="py-4 lg:py-8 lg:pb-20">
        <h1 class="text-2xl lg:text-3xl text-gray-800 text-center mb-6">Add to Collection</h1>
        <div class="max-w-screen-md mx-auto">
            <h1 class="text-2xl text-gray-800 mb-6 flex items-center" v-if="this.urlData">
                {{ this.urlData.video.name }}
            </h1>
            <div ref="wrapper" class="w-full">
                <div ref="player"></div>
            </div>
            <p class="text-sm text-gray-700 mt-4 p-2 bg-yellow-100 text-center rounded" v-show="alreadyImported">It looks like you've already added this video, importing again will replace the current loops with these ones.</p>
            <div v-if="urlData && urlData.loops.length > 0">
                <Loop v-for="loop in urlData.loops" :key="loop.id" :loop="loop" :readmode="true">
                    <LoopBar 
                        :start="loop.start" 
                        :end="loop.end" 
                        :stepAmount="stepAmount" 
                        :id="loop.id" 
                        :countInMode="false"
                        :countInAmount="false"
                        @loop:clicked="handleLoopClicked"
                    />
                </Loop>
            </div>
            <div class="py-6" v-show="urlData">
                <button class="flex items-center justify-center px-4 py-4 text-white bg-primary rounded mx-auto w-3/4 opacity-70 hover:opacity-100 transition-opacity duration-150" @click="handleImport">
                    <PlusIcon size="1.2x"/>
                    <span class="ml-1">Import Video</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import db from '../db';
    import YTPlayer from 'yt-player';
    import LoopBar from '../components/LoopBar';
    import Loop from '../components/Loop';
    import { JSONUncrush } from '@madono/jsoncrush';
    import {
        PlusIcon
    } from 'vue-feather-icons';
    
    export default {
        name: 'ImportView',
        components: {
            Loop,
            LoopBar,
            PlusIcon
        },
        data() {
            return {
                urlData: null,
                player: null,
                stepAmount: 0,
                alreadyImported: false
            }
        },
        async mounted() {
            if (!this.$route.query.data && !this.$route.query.c) {
                this.$router.push('/');
            }
            
            if (this.$route.query.data) {
                const { data: rawData } = this.$route.query;
                let data = JSON.parse(decodeURIComponent(rawData));
                this.urlData = data;
            }

            if (this.$route.query.c) {
                const { c: crushedData } = this.$route.query;
                let data = JSON.parse(JSONUncrush(decodeURIComponent(crushedData)));
                this.urlData = data;
            }

            
            if (this.urlData && this.$refs.wrapper) {
                this.player = new YTPlayer(this.$refs.player, {
                    width: this.$refs.wrapper.clientWidth,
                    height: this.$refs.wrapper.clientWidth/(16/9),
                    timeupdateFrequency: 1000/24
                });
                this.stepAmount = this.$refs.wrapper.clientWidth / this.urlData.video.duration;
                this.player.load(this.urlData.video.videoId);

                // do a check to see if the video actually is already imported
                const count = await db.videos.where('videoId').equals(this.urlData.video.videoId).count();
                this.alreadyImported = count > 0;
            }
        },
        methods: {
            handleImport() {
                if (!this.alreadyImported) {
                    try {
                        db.transaction('rw', db.videos, db.loops, async () => {
                            const { video: { name, videoId, duration }, loops } = this.urlData;
                            const newVideoId = await db.videos.put({
                                name,
                                videoId,
                                duration
                            })
                            const preparedLoops = loops.map(loop => {
                                return {
                                    name: loop.name,
                                    start: loop.start,
                                    end: loop.end,
                                    videoId: newVideoId
                                }
                            })
                            await db.loops.bulkPut(preparedLoops);
                            this.$emit('fetch-videos');
                            this.$router.push('/');
                        })
                    } catch (err) {
                        if (err) {
                            console.log(err);
                            console.log('failed to import video and loops');
                        }
                    }
                }

                if (this.alreadyImported) {
                    try {
                        db.transaction('rw', db.videos, db.loops, async () => {
                            const { video: { videoId }, loops } = this.urlData;
                            const existingVideo = await db.videos.where('videoId').equals(videoId).first();
                            // delete all existing loops on this video
                            const currentLoops = await db.loops.where('videoId').equals(existingVideo.id);
                            await currentLoops.delete();
                            // now make news one based on the data and use the existing videoId
                            const preparedLoops = loops.map(loop => {
                                return {
                                    name: loop.name,
                                    start: loop.start,
                                    end: loop.end,
                                    videoId: existingVideo.id
                                }
                            })
                            await db.loops.bulkPut(preparedLoops);
                            this.$emit('fetch-videos');
                            this.$router.push('/');
                        })
                    } catch (err) {
                        if (err) {
                            console.log(err);
                            console.log('failed to import video and loops');
                        }
                    }
                }
            },
            handleLoopClicked(loopId) {
                let [loop] = this.urlData.loops.filter(loop => loop.id === loopId);
                if (loop) {
                    this.player.seek(loop.start);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>