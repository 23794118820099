<template>
    <div 
        class="bg-gray-200 mt-2 border border-black border-opacity-10 shadow-md transition-opacity duration-150"
        :class="{
            'opacity-100': activeLoopId === loop.id || readMode,
            'opacity-50 hover:opacity-70': activeLoopId !== loop.id && !readMode
        }"
        @click="$emit('activate', loop.id)"
    >
        <div class="text-xs uppercase tracking-wide text-gray-500 pl-2 py-2 flex items-center">
            <!-- When not editing -->
            <span v-show="!editMode">{{ loop.name }}</span>
            <button class="w-6 h-6 ml-2 flex items-center justify-center hover:bg-gray-300" @click="editMode = true" v-show="!editMode && !readMode">
                <Edit2Icon size="1.2x" />
            </button>

            <!-- When Editing -->
            <input type="text" v-model="name" class="uppercase text-xs tracking-wide p-1" v-show="editMode" @keyup.enter="$emit('name-change', { name, loopId: loop.id }), editMode = false">
            <button class="w-6 h-6 ml-2 flex items-center justify-center hover:bg-gray-300" @click="$emit('name-change', { name, loopId: loop.id }), editMode = false" v-show="editMode">
                <CheckIcon size="1.2x" />
            </button>

            <button class="ml-auto w-6 h-6 flex items-center justify-center hover:bg-red-400 hover:text-white mr-4" @click="$emit('delete', { loopId: loop.id })" v-show="!readMode">
                <TrashIcon size="1.2x" />
            </button>
        </div>
        <div class="bg-gray-300 h-10 relative">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import { 
        Edit2Icon, 
        CheckIcon, 
        TrashIcon 
    } from 'vue-feather-icons';
    
    export default {
        name: 'Loop',
        props: ['loop', 'activeLoopId', 'readmode'],
        components: {
            Edit2Icon,
            CheckIcon,
            TrashIcon
        },
        data() {
            return {
                name: this.loop.name,
                editMode: false,
                readMode: this.readmode || false
            }
        },
        methods: {
            
        }
    }
</script>