<template>
    <div>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <router-link to="/">
                <img class="logo" src="../assets/logo-pink-light.svg" alt="Loopsome">
            </router-link>
          </div>
          <nav class="ml-auto mr-16 hidden md:block">
              <ul class="text-gray-600 flex items-center justify-center space-x-6">
                    <li>
                        <router-link to="/what">
                            What is this?
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/how">
                            How to use
                        </router-link>
                    </li>
                    
              </ul>
          </nav>
            <button class="flex items-center p-2  md:px-3 md:py-2 bg-primary text-white rounded" @click="handleClick">
                <PlusIcon/>
                <span class="ml-1 hidden md:inline-block">Add Video</span>
            </button>
        </div>
        <nav class="md:hidden mt-6">
            <ul class="text-gray-600 flex items-center justify-center space-x-6">
                    <li>
                        <router-link to="/what">
                            What is this?
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/how">
                            How to use
                        </router-link>
                    </li>
                    
            </ul>
        </nav>
    </div>
</template>

<script>
    import { 
        // RepeatIcon, 
        PlusIcon 
    } from 'vue-feather-icons';
    export default {
        name: 'Header',
        components: {
            // RepeatIcon,
            PlusIcon
        },
        methods: {
            handleClick() {
                this.$emit('add-video')
            }
        }
    }
</script>

<style lang="css" scoped>
    .logo {
        max-width: 170px;
    }
    nav .router-link-exact-active {
        border-bottom: 2px solid var(--primary);
    }
</style>