import { render, staticRenderFns } from "./LoopBar.vue?vue&type=template&id=dc5d95d4&scoped=true&"
import script from "./LoopBar.vue?vue&type=script&lang=js&"
export * from "./LoopBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc5d95d4",
  null
  
)

export default component.exports