<template>
    <div class="py-16 container mx-auto">
        <div class="content px-4 mx-auto">
            <h1 class="text-3xl mb-6 text-gray-900">What is this?</h1>
            <p class="mb-4 text-lg">Loopsome is a tool that allows you to add YouTube videos and then loop any part of it for learning.</p>
            <p class="mb-4 text-lg"> You can use it for learning Music, another Language, a Dance Routine, a never ending Workout?</p>
            <p class="mb-8 text-lg">If it's on YouTube, you can loop some it.</p>
            
            <h2 class="text-2xl mb-6 text-gray-900">Why make it?</h2>
            <p class="mb-4">
                In the second UK lockdown of the covid-19 pandemic I picked up playing Bass guitar, I wanted to be able to slow down and learn the parts of songs while reading the Bass tabs.
            </p>
            <p class="mb-4">
                I made Loopsome because I feel I could make a better version than some existing solutions I tried (also we're stuck inside I got nothing but time).
            </p>
            <p class="mb-4">
                I wanted something that could do some of the following:
            </p>

            <ul class="mb-8 list-disc pl-4 space-y-2">
                <li>Not need you to sign up to use basic features.</li>
                <li>Store and keep record of videos and loops without needing a server or have people signup.</li>
                <li>Store multiple loops and be able to toggle through them.</li>
                <li>Have a ui that is straightforward to use.</li>
                <li>Provide a faily decent mobile experience since I envision using it both on desktop and a phone.</li>
            </ul>
            
            <h2 class="text-2xl mb-6 text-gray-900">Why don't you have to sign up?</h2>
            <p class="mb-4">
                All the information is stored locally in your web browser. Also servers are hassle. Doing it this way means I don't have to worry about looking after your data, and you don't have to sign up to another service.
            </p>
            <p class="p-4 bg-yellow-100">
                This does have a drawback though, you won't have all your loops and videos shared across devices and browsers. 
                <br><br>
                I do have a few ideas knocking around that might allow you to get them into other browsers and devices though.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'What'
    }
</script>

<style lang="css" scoped>
    .content {
        width: 100%;
        max-width: 80ch;
        @apply text-gray-700;
    }
</style>